.listItem {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.navbar {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px 20px 20px 10px;
    border-right: 1px solid #d1d1d1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;

    @media screen and (max-width: 485px) {
        display: none;
    }
}

.privacycontainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.Link {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    border-radius: 10px;
    padding: 6px 8px;

    &:hover {
        background-color: var(--primary-color);
    }

    [data-mantine-color-scheme="dark"] & {
        color: var(--off-white);

        &:hover {
            background-color: var(--secondary-color);
        }
    }
}

.content {
    max-width: 90%;
    width: 65%;
    margin-left: 20px;

    @media screen and (max-width: 485px) {
        width: 100%;
    }
}

.cookieaffix {
    width: 100%;
    min-height: 60px;
    max-height: fit-content;
    border-radius: 10px;
    background-color: whitesmoke;
    padding: 10px 30px;

    @media screen and (max-width: 485px) {
        padding: 10px;
    }
}

.cookiepara {
    font-size: 0.9rem;
    width: 80%;
    margin: 6px;
}

.cookiebanner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.cookieLink {
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}