.breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 30px 10px 30px;
    height: fit-content;
    padding: 12px;

}

.expbreadcrumb {
    margin: 10px 0px;
    padding: 6px;
}

.breadcrumbText {
    width: fit-content;
    font-size: 0.9rem;
    color: gray;
    margin: 0px 6px;
    text-transform: capitalize;
}

.breadcrumbLink {
    width: fit-content;
    font-size: 0.9rem;
    color: gray;
    margin: 0px 6px;
    text-transform: capitalize;


    &:hover {
        color: var(--link-blue);
        cursor: pointer;
        text-decoration: underline;
    }
}

.carousel {
    width: 80%;
    margin: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 10px;
    }
}

.filterStack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 85px;
    width: 90px;
    gap: 6px;
    padding: 5px;

    &:hover {
        cursor: pointer;
        border-bottom: 1px solid var(--secondary-color);
    }
}

.filterlabel {
    font-weight: 400;
    color: light-dark(var(--mantine-color-dark-8), var(--mantine-color-white));
    font-size: 0.85rem;
    text-align: center;
    margin: 0px;
    line-height: 1.1;


}

.activityGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 95%;
    height: 130px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
    }

    &:hover {
        cursor: pointer;
    }
}