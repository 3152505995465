.pagecontainer {
    display: flex;
    flex-direction: column;
    padding: 10px 50px;

    @media screen and (max-width: 768px) {
        padding: 10px 50px;
        overflow-x: hidden;
    }

    @media screen and (max-width: 500px) {
        padding: 10px 25px;
        overflow-x: hidden;
    }

    @media screen and (max-width: 375px) {
        padding: 10px;
        overflow-x: hidden;
    }
}

.textpagecontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 150px;

    @media screen and (max-width: 768px) {
        padding: 10px 50px;
        overflow-x: hidden;
    }

    @media screen and (max-width: 460px) {
        padding: 10px 35px;
        overflow-x: hidden;
    }
}

.h1heading {
    font-size: 2rem;
    font-weight: 400;
    margin: 20px 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-align: center;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.h2heading {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-align: center;

    @media screen and (max-width: 460px) {
        font-size: 1.2rem;
    }
}

.h2heading a {
    text-decoration: underline;
}

.indexgroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 40px;

    @media screen and (max-width: 485px) {
        flex-direction: column;
        gap: 20px;
    }
}