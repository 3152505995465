.file {
    border: 1px solid #ccc;
    padding: 10px;
    height: 100px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.fileStack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px;
}


.uploadlabel {
    font-size: 1rem;
    margin: 6px;

}

.picupload {
    border: 1px solid #ccc;
    padding: 10px;
    height: 100px;
    width: 300px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.previewimg {
    width: 160px;
    height: auto;
    border-radius: 10px;
    margin: 10px;
}