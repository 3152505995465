.disclaimar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
}

.footertext {
    margin: 5px;
    text-align: center;
    font-size: 0.9rem;

    @media screen and (max-width: 460px) {
        font-size: 0.8rem;
    }
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    border-top: 1px solid #ccc;
    background-color: var(--off-white);
    padding: 15px;
    align-items: center;
    align-content: center;
    gap: 12px;
    justify-content: center;
    justify-items: center;

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
        color: var(--off-white);
    }
}

.menugroup {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: flex-start;
    justify-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
    width: fit-content;
    height: 160px;
}

.menucard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    width: 180px;
}

.heading {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 0px;
}

.link {
    font-size: 0.9rem;
    font-family: "Montserrat", sans-serif;

    &:hover {
        text-decoration: underline;
        font-size: 0.9rem;
        cursor: pointer;
    }
}

.mobilemenu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
}

.mobileLink {
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
    margin: 4px;
    border-right: 1px solid #ccc;
    padding: 1px 10px 1px 0px;

    &:hover {
        text-decoration: underline;
    }
}

.socialgroup {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    gap: 20px;
    height: 35px;
}