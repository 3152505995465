.h2heading {
    font-size: 2.2rem;
    margin: 25px 15px;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    align-self: center;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
        margin: 10px;
    }
}

.sectionHeading {
    font-size: 2rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    letter-spacing: 1px;

    @media screen and (max-width: 485px) {
        font-size: 1.5rem;
        line-height: 1.1;
        margin: 8px;
    }
}

/* blog */

.blogcard {
    margin: 6px;
    padding: 12px;
    border: 0.5px solid #e0e0e0;
    width: 280px;
    height: 360px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
        border: 1.5px solid #e0e0e0;
    }

    @media screen and (max-width: 992px) and (min-width: 600px) {
        width: 250px;
        height: 350px;
    }
}

.blogcardtitle {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
    text-transform: capitalize;
    width: fit-content;
    margin: 5px;
    letter-spacing: 0.2px;
}

.bloggroup {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
    }
}

/* city card */

.citycard {
    height: 320px;
    width: 240px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;

    &:hover {
        transform: scale(1.02);
        transition: transform 0.5s;
        cursor: pointer;
    }
}

.citycardtitle {
    color: white;
    font-size: 1.875rem;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
    z-index: 1;
    line-height: 1.1;
}

/* collection */
.collectioncard {
    margin: 10px;
    padding: 12px;
    border: 0.5px solid #e0e0e0;
    width: 280px;
    height: 270px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background-repeat: no-repeat;

    &:hover {
        border: 1.5px solid #e0e0e0;
        cursor: pointer;
    }

    @media (max-width: 768px) and (min-width: 665px) {
        width: 260px;
        height: 280px;
        margin: 10px;
    }
}

.collectioncardtitle {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    text-transform: capitalize;
    width: fit-content;
    align-self: center;
    margin: 5px;
}

.collectionwrapper {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;

    @media screen and (max-width: 768px) {
        gap: 6px;
    }

    @media screen and (max-width: 665px) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
}

/* /weather */

.weatherCard {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    max-width: 200px;
    margin: 30px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.272);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 485px) {
        width: 120px;
        height: 130px;
        padding: 6px;
        margin: 5px;
    }
}

.weatherIcon {
    align-items: center;
    margin-bottom: 3px;
    margin-top: 10px;

    @media screen and (max-width: 485px) {
        height: 25px;
        width: 25px;
    }
}

.tempLabel {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 2px;

    @media screen and (max-width: 485px) {
        font-size: 1rem;
        margin-bottom: 5px;
    }
}

.weatherLabel {
    color: white;
    align-items: center;
    margin-top: 2px;
    font-weight: 400;
    font-size: 1rem;

    @media screen and (max-width: 485px) {
        font-size: 0.7rem;
        margin-bottom: 5px;
    }
}

.weathersource {
    align-self: flex-end;
    font-size: 0.75rem;
    margin-top: 3px;
    color: white;
    font-style: italic;

    @media screen and (max-width: 485px) {
        font-size: 0.5rem;
        align-self: flex-end;
        margin-bottom: 3px;
    }
}

/* partner content */
.contentpartner {
    font-size: 0.9rem;
    margin: 8px 5px;
    text-align: left;
}


/* feature country */
.controls {
    position: absolute;
    top: 30%;
}

.featureCard {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    justify-items: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        transition: transform 0.5s;
    }
}

.featureImage {
    object-fit: cover;
    border-radius: 10px;
    width: 120px;
    height: 160px;

    &:hover {
        transform: scale(1.02);
        transition: transform 0.5s;
    }
}

.featureHeading {
    font-size: 1.06rem;
    font-weight: 500;
    margin: 10px;
    text-align: center;
    line-height: 1.2;
    height: fit-content;

}

.featurecardtitle {
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
    z-index: 1;
    line-height: 1.1;


    @media screen and (max-width: 440px) {
        font-size: 0.9rem;
    }
}

/* fieldnote */
.fieldnotecard {
    margin: 6px;
    padding: 12px;
    border: 0.5px solid #e0e0e0;
    width: 240px;
    height: 280px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
        transform: scale(1.01);
    }
}

.fnpara {
    font-size: 1.2rem;
    text-align: center;
    margin: 5px;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
}

.fnparaspan {
    text-transform: capitalize;
}

/* partnercard */
.partnercardgroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.partnercard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    border: 0.5px solid #ccc;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin: 10px;
    width: 440px;
    height: 255px;

    @media screen and (max-width: 485px) {
        width: 320px;
    }
}

.cardinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 12px;
    margin-left: 25px;
    width: 70%;

    @media screen and (max-width: 485px) {
        gap: 10px;
        margin-left: 18px;
        width: 100%;
    }
}

.cardcontent {
    font-size: 1rem;
    margin: 5px;
    width: 80%;

    @media screen and (max-width: 912px) {
        font-size: 0.9rem;
        width: 100%;
    }
}

/* feature city */
.featurecitygroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 6px;

    @media screen and (max-width: 440px) {
        gap: 6px;
    }
}

.citycardfeature {
    height: 173px;
    width: 130px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;

    &:hover {
        transform: scale(1.02);
        transition: transform 0.5s;
        cursor: pointer;
    }

    @media screen and (max-width: 440px) {
        width: 110px;
        height: 160px;
    }
}

.customcard {
    width: 240px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
    }
}

.customcardimg {
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.customlabel {
    text-align: center;
    font-size: 1.3rem;
    margin: 10px;
}

.cardgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 610px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

.bookingcard {
    border: 0.5px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    width: 240px;
    height: 340px;
    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
    }
}

.bookingcardinfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;

    @media screen and (max-width: 460px) {
        padding: 5px;
        height: fit-content;
    }
}

.bookingcardtitle {
    font-size: 1rem;
    margin: 6px;
    text-transform: capitalize;
    line-height: 1.2;

    @media screen and (max-width: 460px) {
        font-size: 0.8rem;
        margin: 2px;
    }
}

.bookingcardlabel {
    color: gray;
    font-size: 0.8rem;
    text-transform: capitalize;
    margin: 4px;

    @media screen and (max-width: 460px) {
        font-size: 0.7rem;
        margin: 2px;
    }
}

.deslabel {
    text-align: center;
    font-size: 1.8rem;
    margin: 10px;
    color: white;
    z-index: 2;
    letter-spacing: 0.5px;
}

.descard {
    width: 240px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
    }
}

.blogdate {
    font-size: 0.8rem;
    margin: 5px;
}