.navcontenttitle {
    margin: 10px 0px;
    font-size: 1.7rem;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.bookingcardgroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;
}

.bookingbuttongroup {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 6px;
    @media screen and (max-width: 460px) {
        margin: 6px 3px;
    }
}

.bookingmodal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 6px;

    @media screen and (max-width: 460px) {
        max-width: 300px;
    }
}
.modaltitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 10px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0.5px;
}

.modalimg {
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.modalinfo {
    font-size: 1rem;
    margin: 2px 12px;
    text-align: left;
    align-self: flex-start;
    max-width: 350px;
}

.modalinfospan {
    font-weight: 500;
    font-size: 1.05rem;
}

.modalsubtitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 6px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0.5px;
}

.h2title {
    font-size: 1.5rem;
    margin: 6px 0px;
}

.rewardwording {
    font-size: 1.3rem;
    margin: 6px 0px;
    font-family: "Lora", serif;
}
.dashboardCard {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #ccc;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 30px;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 15px;

    @media screen and (max-width: 430px) {
        padding: 20px 15px;
        max-width: 325px;
    }
}

.badgeCard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 460px) {
        flex-direction: column;
    }
}

.badge {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 20px;
    align-items: center;
    justify-content: flex-start;
}

.badgename {
    font-size: 1.1rem;
}

.accountcontainer {
    padding: 3px 15px;
}

.cardheading {
    font-size: 1.4rem;
    margin: 0px;
    text-transform: capitalize;
    text-align: left;
}

.rewardnumber {
    color: cadetblue;
    font-weight: 600;
    font-size: 1.3rem;
}

.para {
    max-width: 750px;
    @media (max-width: 430px) {
        max-width: 300px;
    }
}

.admincard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    width: 300px;
    height: auto;
    padding: 20px;
}
.admincount {
    font-size: 1rem;
    margin-left: 10px;
    color: var(--secondary-color);
    font-weight: 500;
}
