.accountsigninpage {
    padding: 40px;
}

.userwindow {
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    max-width: 250px;
    min-width: 190px;
    border: 0.5px solid #ccc;
    padding: 15px 10px;
    background-color: white;
    height: 150px;

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
    }
}

.usertext {
    font-size: 0.9rem;
    margin: 6px;
}

.accountUpdate:hover {
    cursor: pointer;
}

.acform {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 460px) {
        max-width: 320px;
    }
}

.navcontenttitle {
    margin: 10px 0px;
    font-size: 1.7rem;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.succssnote {
    font-size: 1.1rem;
    color: green;
    margin: 10px;
}