.h2heading {
    font-size: 2.2rem;
    margin: 25px 15px;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    align-self: center;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
        margin: 10px;
    }
}

.h3heading {
    font-size: 1.7rem;
    margin: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;

    @media screen and (max-width: 485px) {
        font-size: 1.2rem;
    }
}

/* advice */
.tipcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.wktheading {
    font-size: 1.4rem;
    margin: 30px 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    line-height: 1.2;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.tipcard {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: fit-content;
    margin: 15px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.272);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    max-width: 800px;

    @media screen and (max-width: 485px) {
        width: 300px;
        height: auto;
    }

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
    }
}

.tipmap {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    margin-top: 15px;

    @media screen and (max-width: 485px) {
        width: 300px;
        height: auto;
    }
}

.bullet {
    size: 22px;
}

.content {
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: 18px;
    font-weight: 400;
    margin-left: 0px;
    margin-right: 20px;
}

.themeicon {
    border-radius: 50px;
    background-color: var(--secondary-color);
}

.timelineroot {
    margin: 10px;
}

.spoilercontrol {
    color: gray;
    font-size: 0.9rem;
    margin: 6px;
}

.blogcontainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.blogimg {
    align-self: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;

    @media screen and (max-width: 400px) {
        width: 280px;
        height: auto;
    }
}

.caption {
    font-size: 0.9rem;
    line-height: 1.3;
    text-align: center;
    color: gray;
    max-width: 400px;
    align-self: center;
    margin-bottom: 15px;
    margin-top: 6px;

    @media screen and (max-width: 820px) {
        font-size: 0.8rem;
        margin-bottom: 6px;
    }
}

.blogtitle {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    align-self: center;

    text-transform: capitalize;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.content {
    font-size: 1.1rem;

    @media screen and (max-width: 460px) {
        font-size: 1rem;
    }
}

.subheading {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    text-transform: capitalize;

    @media screen and (max-width: 485px) {
        font-size: 1.3rem;
    }
}

.navcontenttitle {
    margin: 10px 0px;
    font-size: 1.7rem;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.para {
    max-width: 750px;


    @media (max-width: 430px) {
        max-width: 300px;
    }
}

.navsubheading {
    font-size: 1.35rem;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
}

.boldtext {
    font-weight: 600;
}

.spanlink {
    font-style: italic;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        text-decoration-color: var(--link-blue);
    }
}

/* foundernote */
.h2heading {
    text-align: left;
    font-size: 1.9rem;
    letter-spacing: 0.8px;

    @media screen and (max-width: 460px) {
        font-size: 1.5rem;
    }
}

.aboutSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 25px;
    margin-top: 0px;
    padding: 15px 20px;
    border-radius: 10px;
    border: 0.5px solid var(--off-white);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.244);

    @media screen and (max-width: 820px) {
        flex-direction: column;
        align-items: center;
        gap: 3px;
    }
}

.profileimg {
    border-radius: 10px;
    width: 250px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 6px;
    align-self: center;

    @media (max-width: 820px) {
        width: 200px;
        margin-top: 5px;
        margin-bottom: 6px;
    }
}

.subtitle {
    margin-bottom: var(--mantine-spacing-md);
    font-size: 1.3rem;
    font-weight: 400;

    @media screen and (max-width: 430px) {
        font-size: 1.2rem;
        max-width: 350px;
    }
}

.consheading {
    font-size: 1.6rem;
    margin-bottom: 6px;
    line-height: 1.3;
    margin-top: 2px;
    text-align: left;
    text-transform: capitalize;
    font-style: italic;

    @media screen and (max-width: 700px) {
        text-align: center;
    }
}

.conspara {
    margin: 2px;
    font-size: 1rem;
}

.consimg {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-self: center;
    margin-bottom: 10px;

    @media screen and (max-width: 820px) {
        width: 240px;
        height: 180px;
        margin-bottom: 6px;
        order: -1;
    }
}

/* index */
.indexgroup {
    margin: 15px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;

    @media screen and (max-width: 485px) {
        flex-direction: column;
        gap: 10px;
    }
}

.textcontent {
    width: 60%;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 1rem;

    @media screen and (max-width: 485px) {
        width: 100%;
    }
}

.indexstackkodama {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 40%;

    @media screen and (max-width: 485px) {
        width: 100%;
        padding: 10px;
        order: -1;
    }
}

.containerheading {
    font-size: 2rem;
    font-weight: 400;
    margin: 6px;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    letter-spacing: 1px;

    @media screen and (max-width: 485px) {
        font-size: 1.5rem;
    }
}

.ktimage {
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid black;
    max-height: 210px;
}

.ktsource {
    font-size: 12px;
    text-align: center;
    margin-top: 6px;
}

/* supplierwhy */

.suppliercardstack {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.suppliercard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 5px;
    gap: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 460px) {
        width: 300px;
    }
}

.supplierinfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 70%;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.cardheading {
    font-size: 1.4rem;
    margin: 0px;
    text-transform: capitalize;
    text-align: left;
}

.suppliercontent {
    font-size: 1rem;
    margin: 0px;
}

.indexstack {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 40%;

    @media screen and (max-width: 485px) {
        width: 100%;
        padding: 10px;
    }
}

.sectionHeading {
    font-size: 2rem;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 1.2;
    text-transform: capitalize;
    letter-spacing: 1px;

    @media screen and (max-width: 485px) {
        font-size: 1.5rem;
    }
}

.wktcardgroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
    gap: 25px;

    @media screen and (max-width: 485px) {
        flex-direction: column;
        padding: 0px;
        margin-top: 5px;
        align-items: center;
        gap: 10px;
    }
}

.wktcard {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    max-width: 31%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 10px;

    @media screen and (min-width: 461px) and (max-width: 768px) {
        padding: 0px;
        width: 37%;
    }

    @media screen and (max-width: 485px) {
        max-width: 100%;
    }
}

.wktimage {
    justify-content: center;
    align-self: center;
    object-fit: cover;
}

.wktcontent {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.4;
    align-self: flex-start;
    margin-top: 5px;

    @media screen and (max-width: 768px) {
        max-width: 90%;
    }
}

.indexcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    gap: 6px;
    margin-top: 20px;
}

.indexstackkodama {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 40%;

    @media screen and (max-width: 485px) {
        width: 100%;
        padding: 10px;
        order: -1;
    }
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    margin: 25px 10px;

    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 6px;
    }
}

.contentpane {
    margin-top: 10px;
    padding: 10px;
    justify-content: center;
    justify-items: center;

    @media screen and (max-width: 820px) {
        height: fit-content;
        margin: 6px;
        padding: 0px;

    }
}

.titlepane {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;

    @media screen and (max-width: 820px) {
        width: 100%;
        height: fit-content;
        margin: 3px 6px;
        padding: 5px;
        order: -1;

    }
}

.container {
    margin: 20px 10px;
}

.customimage {
    margin: 10px;
    align-self: center;

    @media screen and (max-width: 480px) {
        width: 300px;
        height: auto;
        margin: 5px;

    }
}

.booksubheading {
    font-size: 1.1rem;
    margin-top: 0px;
    margin-left: 15px;

    @media screen and (max-width: 480px) {
        margin-left: 10px;
    }
}

.highlighttext {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 10px;
    text-decoration-color: var(--primary-color);
    font-style: italic;
}

.capitalise {
    text-transform: capitalize;
}

.accorditem {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
    margin-bottom: 6px;
    min-width: fit-content;
    padding: 2px 5px;


    @media screen and (max-width: 460px) {
        max-width: 320px;
    }
}

.accordpanel {
    padding: 10px 5px;
    font-size: 1rem;

    @media screen and (max-width: 460px) {
        max-width: 320px;
    }
}

.rewardh2heading {
    text-transform: capitalize;
    font-size: 1.35rem;
    margin-top: 20px;
    margin-bottom: 6px;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
    text-decoration-thickness: 5px;
}

.rewardh3heading {
    text-transform: capitalize;
    font-size: 1.25rem;
    margin: 10px 0px 6px 0px;
}

.cardGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
}

.customspantext {
    font-weight: 600;
    color: var(--secondary-color);

}

.blogh3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-style: italic;
    margin-top: 6px;
    margin-bottom: 6px;
}

.buttonleft {
    justify-self: flex-start;
}

.buttongroup {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-self: flex-start;

}