/* shared */

.name {
    font-size: 0.85rem;
    font-weight: 400;
    text-align: center;
    max-width: 120px;
    line-height: 1.2;
    text-transform: capitalize;
    margin-top: 2px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    margin: 8px;
    border-radius: 10px;
    height: 85px;
    width: 90px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

    &:hover {
        border: 1px solid #ccc;
        cursor: pointer;
    }
}

.icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
}

/* currency */
.currencypopup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 5px;
    padding: 10px;
}

.currencylabel {
    width: 110px;
    height: 30px;
    padding: 3px 6px;

    &:hover {
        cursor: pointer;
        background-color: var(--primary-color);
    }

    [data-mantine-color-scheme="dark"] &:hover {
        border-bottom: 1px solid var(--primary-color);
    }
}

.currencyText {
    font-size: 0.9rem;
}

/* modal */
.modalgroup {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-width: 320px;
    margin: 6px;
    max-width: 450px;

    @media screen and (max-width: 420px) {
        min-width: 280px;
        max-width: 310px;
        justify-content: center;
    }
}

/* activity modal  */
.activitycard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6px 15px;
    border-radius: 10px;
    height: 100px;
    width: 130px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
        border: 0.5px solid #ccc;
    }

    @media screen and (max-width: 400px) {
        margin: 6px;
    }
}

.activityModal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 450px;
    margin: 5px;

    @media screen and (max-width: 400px) {
        min-width: 280px;
        max-width: 310px;
    }
}

.experienceicon {

    margin-bottom: 5px;
}

/* tab */

.countrylist {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
}

.tabcard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 6px;
    width: 80px;
    height: 80px;
    padding: 3px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

.tabgroup {
    display: flex;
    flex-direction: row;
    padding: 6px;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.experiencecard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    margin: 5px;
    border-radius: 10px;
    height: 80px;
    width: 120px;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
}

/* region ok*/

.regioncard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    align-items: center;
    padding: 8px;
    justify-content: flex-start;
    border-radius: 10px;
    height: 120px;
    width: 100px;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
}

.regionmap {
    border-radius: 10px;
}

/* header */
.header {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 70px;
    margin-bottom: 10px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 15px;

    @media screen and (max-width: 468px) {
        padding: 10px;
        gap: 5px;
    }
}

.logotext {
    font-weight: 400;
    color: light-dark(var(--mantine-color-dark-8), var(--mantine-color-white));
    font-size: 1.1rem;
    line-height: 1.1;
    font-family: "Montserrat", sans-serif;
    margin: 0px 3px;

    @media screen and (max-width: 468px) {
        font-size: 1rem;
    }

    &:hover {
        cursor: pointer;
        font-weight: 500;
    }
}

.popoverdropdown {
    width: 700px;
    box-shadow: --var(--mantine-shadow-md);
}

/* headermenu */
.greeting {
    min-width: fit-content;
    font-size: 1rem;

    @media screen and (max-width: 485px) {
        font-size: 0.9rem;
    }
}

.menu_text {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;

    [data-mantine-color-scheme="dark"] & {
        color: #ccc;
    }
}

.menucircle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    border-radius: 30px;
    border: 1px solid #333;
    padding: 2px 6px;
    width: fit-content;

    &:hover {
        cursor: pointer;
        border: 1.5px solid #333;
    }

    [data-mantine-color-scheme="dark"] & {
        border: 1px solid #ccc;

        &:hover {
            border: 1.5px solid #ccc;
        }
    }
}

/* sign in */

.signinform {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 20px;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
}

.emailStack {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.googleroot {
    margin: 10px;
    border-radius: 10px;
    border: 1px solid #333;

    &:hover {
        cursor: pointer;
        border: 1.5px solid #333;
        background-color: white;
    }
}

.googlelabel {
    font-weight: 400;
    color: #333;
    font-size: 1rem;
    border-radius: 15px;
    font-family: "montserrat", sans-serif;

    [data-mantine-color-scheme="dark"] & {
        color: white;
    }
}

.user_menu {
    display: flex;
    flex-direction: row;
    margin-right: 25px;
    gap: 20px;
    align-items: center;
}

.popover {
    border: none;
    position: relative;
}